.participants {
  flex-basis: 250px;
  flex-grow: 0;
  flex-shrink: 0;
  height: 100%;
  border-right: #eaeaea solid 2px;
  padding: 20px 16px 16px 24px;
  overflow: hidden;
}

.participantsList {
  margin-top: 20px;
}

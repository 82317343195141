@import "~@blueprintjs/core/lib/scss/variables";

.msalButton {
  display: flex;
  align-self: flex-start;
  align-items: center;
  background: rgba(25, 119, 243, 0.08);
  border-radius: 40px;
  border: 0;
  padding: 12px 18px;
  margin-top: 8px;
  width: max-content;
  color: #1977f3;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s;
  outline: none;
  
  .logo {
    height: 16px;
    width: 16px;
    margin-right: 8px;
  }
  &:hover {
    background: rgba(25, 119, 243, 0.095);
  }
}

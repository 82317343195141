.omnibar {
  width: 800px;
  left: calc(50% - 400px);
}

.file {
  display: flex;
  flex-direction: row;
  align-items: center;
  .info {
    margin-left: 8px;
    // display: flex;
    // flex-direction: column;
    // .viewed {
    //   font-size: 10px;
    // }
  }
}

.container {
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  justify-content: center;
  li {
    margin-bottom: 8px;
  }
}

@import "../../../../../node_modules/@blueprintjs/core/lib/scss/variables";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconButton {
  margin: 0 8px;
}

.iconButton.iconButton {
  background-color: #eee;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-size: 20px;
  color: $dark-gray1;

  &:hover {
    background-color: #c7c7c7;
  }
}

.iconButton.danger {
  background-color: #ff5a5a;
  color: $light-gray5 !important;
}

.iconButton.filled {
  background-color: $dark-gray1;
  color: $light-gray5 !important;

  &:hover {
    background-color: $dark-gray5;
    color: #eee;
  }
}

.iconButton.danger:hover {
  background-color: $red2;
}


.mobile {
  display: none;
}

@media (max-width: 800px) {
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
}
.msalButton {
  margin-top: 4px;
}

.emailLogin {
  margin-top: 45px;
}

.terms {
  margin-top: 60px;
  color: #808080;

  a {
    color: #1977f3;
  }
}

@media (prefers-color-scheme: dark) {
  .loginViaExtension {
    color: white;

    input {
      color: white;
    }
  }
}

$header-height: 80px;

.container {
  width: 100%;
  .header {
    .top {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
    }
    display: flex;
    // height: 150px;
    // @media (max-width: 480px) {
    //   height: 80px;
    // }
    flex-direction: column;
    margin-bottom: 32px;
    // @media (max-width: 600px) {
    //   // margin-bottom: 0px;
    // }
    .actions {
      margin-bottom: 8px;
    }
  }
  .content {
    // display: flex;
    // flex: 1;
    padding-bottom: 50px;
  }
}

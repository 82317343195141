.form {
  margin: -4px;

  > * {
    margin: 4px;
  }
}

.input {
  background: rgba(#aaa, 0.15);
  border-radius: 8px;
  box-shadow: none;
  height: 32px;
  min-width: 300px;
  border: 0;
  padding: 8px 16px;
}

.button {
  display: inline-flex;
  align-items: center;
  background: rgba(25, 119, 243, 0.08);
  border-radius: 18px;
  border: 0;
  padding: 6px 12px;
  width: max-content;
  color: #1977f3;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s;
  outline: none;
  min-height: 32px;
  
  &:hover {
    background: rgba(25, 119, 243, 0.095);
  }
}

.error {
  margin-top: 8px;
  color: #c23030
}

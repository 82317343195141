.container {
  height: 100%;
  flex-direction: column;
  .content {
    padding: 100px 50px 0;
    @media (max-width: 480px) {
      padding: 20px 20px 0;
    }

    display: block;
    height: calc(100% - 50px);
    max-width: 1200px;
    margin: auto;
    display: flex;
    justify-content: flex-start;
    &.small {
      max-width: 640px;
      min-width: 0px;
    }
  }
}
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,600&display=swap);
body, html, #root {
  width: 100%;
  height: 100%;
  font-family: 'Montserrat',-apple-system,system-ui,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen-Sans,Ubuntu,Cantarell,'Helvetica Neue',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol';
}

.bp3-button {
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

h1.bp3-heading, h2.bp3-heading {
  margin: 0px;
}

h6.bp3-heading {
  font-weight: 400;
}

:focus {
  outline: none;
}

.bp3-control-indicator {
  outline: none !important;
}

a, a:hover {
  text-decoration: none;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  /*transform: translateX(-110%);*/
  -webkit-transition: opacity 100ms ease-in;
  transition: opacity 100ms ease-in;
  /*transition: transform 2000ms ease-in;*/
}

.global-link, .global-link:hover {
  color: inherit;
}

.bp3-html-table td {
  box-shadow: none !important;
}

.global-spinner .bp3-spinner-animation {
  -webkit-animation: pt-spinner-animation 1000ms linear infinite;
          animation: pt-spinner-animation 1000ms linear infinite;
}

.global-spinner.light .bp3-spinner-animation path {
  stroke: rgba(255, 255, 255, 0.2);
}

.global-spinner.light .bp3-spinner-animation path:last-child {
  stroke: rgba(255, 255, 255, 0.8)
}

.bp3-toast-container-top {
  overflow: visible;
}

.bp3-daterangepicker .DayPicker-Day--hovered-range:not(.DayPicker-Day--selected):not(.DayPicker-Day--selected-range):not(.DayPicker-Day--selected-range-start):not(.DayPicker-Day--selected-range-end) {
    background-color: transparent;
}

.bp3-daterangepicker .DayPicker-Day--hovered-range-start:not(.DayPicker-Day--hovered-range-end) {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.bp3-daterangepicker .DayPicker-Day--outside {
  visibility: visible;
}

.global-roles-menu-item.bp3-active {
  background-color: rgba(115, 134, 148, 0.1) !important;
}
.global-roles-menu-item.bp3-active .bp3-icon{
  color: rgba(115, 134, 148, 0.8) !important;
}

.global-more-button > span {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.styles_container__3cvvO {
  display: flex;
  flex: 1 1;
  height: 100%;
  justify-content: center;
  align-items: center; }

.styles_lds-ripple__1-evw {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px; }

.styles_lds-ripple__1-evw div {
  position: absolute;
  border: 4px solid #000;
  opacity: 1;
  border-radius: 50%;
  -webkit-animation: styles_lds-ripple__1-evw 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
          animation: styles_lds-ripple__1-evw 1s cubic-bezier(0, 0.2, 0.8, 1) infinite; }

.styles_lds-ripple__1-evw div:nth-child(2) {
  -webkit-animation-delay: -1s;
          animation-delay: -1s; }

@-webkit-keyframes styles_lds-ripple__1-evw {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1; }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0; } }

@keyframes styles_lds-ripple__1-evw {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1; }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0; } }

.content_container__2gMmj {
  width: 100%; }
  .content_container__2gMmj .content_header__1hf0p {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px; }
    .content_container__2gMmj .content_header__1hf0p .content_top__2zXrJ {
      flex: 1 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end; }
    .content_container__2gMmj .content_header__1hf0p .content_actions__3MGGm {
      margin-bottom: 8px; }
  .content_container__2gMmj .content_content__32GsC {
    padding-bottom: 50px; }

.tinylayout_container__3IhLJ {
  height: 100%;
  flex-direction: column; }
  .tinylayout_container__3IhLJ .tinylayout_content__3dksA {
    padding: 100px 50px 0;
    display: block;
    height: calc(100% - 50px);
    max-width: 1200px;
    margin: auto;
    display: flex;
    justify-content: flex-start; }
    @media (max-width: 480px) {
      .tinylayout_container__3IhLJ .tinylayout_content__3dksA {
        padding: 20px 20px 0; } }
    .tinylayout_container__3IhLJ .tinylayout_content__3dksA.tinylayout_small__2ySEB {
      max-width: 640px;
      min-width: 0px; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.googlelogin_googleButton__1uVaQ {
  display: flex;
  align-items: center;
  background: rgba(25, 119, 243, 0.08);
  border-radius: 40px;
  border: 0;
  padding: 12px 18px;
  margin-top: 8px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  color: #1977f3;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  outline: none; }
  .googlelogin_googleButton__1uVaQ .googlelogin_logo__3OQu0 {
    height: 16px;
    width: 16px;
    margin-right: 8px; }
  .googlelogin_googleButton__1uVaQ:hover {
    background: rgba(25, 119, 243, 0.095); }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.msallogin_msalButton__3CtmN {
  display: flex;
  align-self: flex-start;
  align-items: center;
  background: rgba(25, 119, 243, 0.08);
  border-radius: 40px;
  border: 0;
  padding: 12px 18px;
  margin-top: 8px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  color: #1977f3;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  outline: none; }
  .msallogin_msalButton__3CtmN .msallogin_logo__19g9O {
    height: 16px;
    width: 16px;
    margin-right: 8px; }
  .msallogin_msalButton__3CtmN:hover {
    background: rgba(25, 119, 243, 0.095); }

.emaillogin_form__2Z0Hz {
  margin: -4px; }
  .emaillogin_form__2Z0Hz > * {
    margin: 4px; }

.emaillogin_input__24Sy- {
  background: rgba(170, 170, 170, 0.15);
  border-radius: 8px;
  box-shadow: none;
  height: 32px;
  min-width: 300px;
  border: 0;
  padding: 8px 16px; }

.emaillogin_button__7UVuS {
  display: inline-flex;
  align-items: center;
  background: rgba(25, 119, 243, 0.08);
  border-radius: 18px;
  border: 0;
  padding: 6px 12px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  color: #1977f3;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  outline: none;
  min-height: 32px; }
  .emaillogin_button__7UVuS:hover {
    background: rgba(25, 119, 243, 0.095); }

.emaillogin_error__jUqAH {
  margin-top: 8px;
  color: #c23030; }

.styles_msalButton__Vb48h {
  margin-top: 4px; }

.styles_emailLogin__1onlq {
  margin-top: 45px; }

.styles_terms__5jlEc {
  margin-top: 60px;
  color: #808080; }
  .styles_terms__5jlEc a {
    color: #1977f3; }

@media (prefers-color-scheme: dark) {
  .styles_loginViaExtension__1AST0 {
    color: white; }
    .styles_loginViaExtension__1AST0 input {
      color: white; } }

.invite_msalButton__3jn2n {
  margin-top: 4px; }

.invite_emailLogin__3WfiR {
  margin-top: 20px; }

.register_msalButton__3pqdk {
  margin-top: 4px; }

.register_emailLogin__2jSB9 {
  margin-top: 20px; }

.styles_container__1b1ra {
  display: flex;
  flex: 1 1;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column; }

.styles_emo__1PZuj {
  font-size: 72px; }

.button_mobile__19cVF {
  display: none; }

@media (max-width: 800px) {
  .button_mobile__19cVF {
    display: block; }
  .button_desktop__1z9O7 {
    display: none; } }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.participant_container__dfhkv {
  width: 320px;
  height: 240px;
  border-radius: 8px;
  background-color: #e2e2e2;
  overflow: hidden;
  display: flex;
  flex-direction: column; }

.participant_streamContainer__1lSnO {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-grow: 1;
  height: 0; }

.participant_stream__cVI5K {
  width: 100%;
  height: 100%;
  background-color: #182026; }
  .participant_stream__cVI5K video {
    width: 100%;
    height: 100%;
    object-fit: contain; }
  .participant_stream__cVI5K video:not(:last-of-type) {
    display: none; }

.participant_streamHidden__2fCkx {
  display: none; }

.participant_streamMirror__1d8_o video {
  -webkit-transform: scale(-1, 1);
          transform: scale(-1, 1); }

.participant_placeholder__3QfiH, .participant_placeholderEmpty__2Nl1s {
  flex-shrink: 0;
  flex-grow: 0;
  height: 128px;
  width: 128px;
  border-radius: 64px; }

.participant_placeholderEmpty__2Nl1s {
  display: flex;
  justify-content: center;
  align-items: center; }

.participant_footer__zIbHp {
  width: 100%;
  position: absolute;
  bottom: 0;
  color: #ffffff;
  padding: 8px 10px 8px;
  background: black;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.297356)), to(rgba(0, 0, 0, 0)));
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.297356) 0%, rgba(0, 0, 0, 0) 100%); }

.participant_header__2VL8M {
  width: 100%;
  position: absolute;
  top: 0;
  color: #ffffff;
  display: flex;
  padding: 2px;
  flex-direction: row-reverse; }

.participant_name__1GkGs {
  font-weight: 600;
  font-size: 14px;
  margin-top: 2px; }

.participant_info__3wF1X {
  font-size: 12px; }

.participant_controls__E1XoH {
  display: flex;
  align-items: center;
  margin-top: 8px; }

.participant_controls__E1XoH > * {
  margin-left: 2px; }

.participant_icon__3Di8A {
  font-size: 22px;
  color: #fff; }

.participant_localControls__1ocO8 {
  padding: 24px 32px; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.roomControls_container__vZ7bl {
  display: flex;
  align-items: center;
  justify-content: center; }

.roomControls_iconButton__1szep {
  margin: 0 8px; }

.roomControls_iconButton__1szep.roomControls_iconButton__1szep {
  background-color: #eee;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-size: 20px;
  color: #182026; }
  .roomControls_iconButton__1szep.roomControls_iconButton__1szep:hover {
    background-color: #c7c7c7; }

.roomControls_iconButton__1szep.roomControls_danger__1AJxh {
  background-color: #ff5a5a;
  color: #f5f8fa !important; }

.roomControls_iconButton__1szep.roomControls_filled__1eVn6 {
  background-color: #182026;
  color: #f5f8fa !important; }
  .roomControls_iconButton__1szep.roomControls_filled__1eVn6:hover {
    background-color: #394b59;
    color: #eee; }

.roomControls_iconButton__1szep.roomControls_danger__1AJxh:hover {
  background-color: #c23030; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.participantListItem_container__1H-RH {
  margin-top: 16px;
  display: flex;
  align-items: center; }

.participantListItem_image__1Q_XH, .participantListItem_imageEmpty__2vB9P {
  flex-shrink: 0;
  flex-grow: 0;
  height: 32px;
  width: 32px;
  border-radius: 16px; }

.participantListItem_imageEmpty__2vB9P {
  background-color: #e2e2e2;
  display: flex;
  justify-content: center;
  align-items: center; }

.participantListItem_name__2yp6t {
  font-weight: 500;
  margin-left: 8px;
  overflow: hidden;
  flex-grow: 1;
  flex-shrink: 1; }

.participantListItem_status__2Ona_ {
  margin-left: 8px; }

.participantListItem_statusOnline__3JeOF {
  color: #15b371; }

.participantListItem_statusOffline__1Ezgw {
  color: #f55656; }

.participantList_participants__1zwkU {
  flex-basis: 250px;
  flex-grow: 0;
  flex-shrink: 0;
  height: 100%;
  border-right: #eaeaea solid 2px;
  padding: 20px 16px 16px 24px;
  overflow: hidden; }

.participantList_participantsList__1waJJ {
  margin-top: 20px; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.room_layout__1i4rw {
  display: flex;
  height: 100%;
  background-color: #fafafa;
  overflow: hidden; }

.room_fullscreenMessage__13yO- {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1; }

.room_room__CNkO9 {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  flex-direction: column; }

.room_roomJoined__24-10 {
  justify-content: unset; }

.room_roomParticipants__10At3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 48px 48px 0;
  overflow: auto; }

.room_roomParticipantsJoined__24zfx {
  flex-grow: 1;
  padding: 48px; }

.room_roomParticipants__10At3 > * {
  margin: 8px; }

.room_footer__1rj_5 {
  padding: 24px 32px; }

.room_join__5rBVO {
  margin-top: 24px;
  display: flex;
  justify-content: center;
  flex: 250px 0; }

.room_joinButton__3SQ2E.room_joinButton__3SQ2E {
  box-shadow: none;
  width: 100%; }
  .room_joinButton__3SQ2E.room_joinButton__3SQ2E:hover {
    box-shadow: none; }

.room_layout__I-Cf6 {
  display: flex;
  height: 100%;
  background-color: #fafafa;
  overflow: hidden;
  justify-content: center;
  align-items: center; }

.downloads_container__2mCuC {
  display: flex;
  flex: 1 1;
  align-items: center;
  flex-direction: column; }
  .downloads_container__2mCuC .downloads_bar__1Kh-H {
    padding: 0 50px;
    background: rgba(255, 255, 255, 0.98);
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.06);
    display: flex;
    align-items: center; }
    .downloads_container__2mCuC .downloads_bar__1Kh-H .downloads_heading__PLmle {
      display: flex;
      flex-direction: row;
      align-items: center; }
      .downloads_container__2mCuC .downloads_bar__1Kh-H .downloads_heading__PLmle .downloads_title__1j3n5 {
        margin-left: 4px; }
      .downloads_container__2mCuC .downloads_bar__1Kh-H .downloads_heading__PLmle .downloads_logo__3VSTV {
        width: 24px;
        height: 24px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat; }
  .downloads_container__2mCuC .downloads_content__PXnYF {
    display: flex;
    flex: 1 1;
    justify-content: center;
    flex-direction: column;
    padding: 0 50px; }
    .downloads_container__2mCuC .downloads_content__PXnYF h1 {
      margin-top: 72px;
      text-align: center; }
    .downloads_container__2mCuC .downloads_content__PXnYF .downloads_links__3-AVx {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      margin-top: 36px; }
      .downloads_container__2mCuC .downloads_content__PXnYF .downloads_links__3-AVx .downloads_group__F9fK3 {
        display: flex;
        flex: 1 1;
        flex-direction: column;
        margin: 0 36px; }
      .downloads_container__2mCuC .downloads_content__PXnYF .downloads_links__3-AVx .downloads_link__LTNun {
        background-color: #000;
        width: 216px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        padding: 8px 16px;
        margin-bottom: 16px; }
        .downloads_container__2mCuC .downloads_content__PXnYF .downloads_links__3-AVx .downloads_link__LTNun:hover {
          -webkit-transform: scale(1.05);
                  transform: scale(1.05);
          -webkit-transition: all .1s ease-in-out;
          transition: all .1s ease-in-out; }
        .downloads_container__2mCuC .downloads_content__PXnYF .downloads_links__3-AVx .downloads_link__LTNun .downloads_apple__MWuc3 {
          width: 36px;
          height: 36px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat; }
        .downloads_container__2mCuC .downloads_content__PXnYF .downloads_links__3-AVx .downloads_link__LTNun .downloads_text__1-LBg {
          margin-left: 16px;
          color: #fff;
          padding-top: 4px; }
          .downloads_container__2mCuC .downloads_content__PXnYF .downloads_links__3-AVx .downloads_link__LTNun .downloads_text__1-LBg .downloads_title__1j3n5 {
            font-size: 10px; }
          .downloads_container__2mCuC .downloads_content__PXnYF .downloads_links__3-AVx .downloads_link__LTNun .downloads_text__1-LBg .downloads_title2__3jt8F {
            font-weight: 600; }

.googledrive_omnibar__2fRTq {
  width: 800px;
  left: calc(50% - 400px); }

.googledrive_file__2mCyK {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .googledrive_file__2mCyK .googledrive_info__3PF1q {
    margin-left: 8px; }

.googledrive_container__rCfyo {
  display: flex;
  flex: 1 1;
  height: 100%;
  align-items: center;
  justify-content: center; }
  .googledrive_container__rCfyo li {
    margin-bottom: 8px; }


@import "../../../../../node_modules/@blueprintjs/core/lib/scss/variables";

.container {
  width: 320px;
  height: 240px;
  border-radius: 8px;
  background-color: #e2e2e2;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.streamContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-grow: 1;
  height: 0;
}

.stream {
  width: 100%;
  height: 100%;
  background-color: $dark-gray1;

  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  video:not(:last-of-type) {
    display: none;
  }
}

.streamHidden {
   display: none;
}

.streamMirror {
  video {
    transform: scale(-1, 1);
  }
}

.placeholder  {
  flex-shrink: 0;
  flex-grow: 0;
  height: 128px;
  width: 128px;
  border-radius: 64px;
}

.placeholderEmpty {
  @extend .placeholder;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  color: $white;
  padding: 8px 10px 8px;
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,0.29735644257703087) 0%, rgba(0,0,0,0) 100%);
}

.header {
  width: 100%;
  position: absolute;
  top: 0;
  color: $white;
  display: flex;
  padding: 2px;
  flex-direction: row-reverse;
}

.name {
  font-weight: 600;
  font-size: 14px;
  margin-top: 2px;
}

.info {
  font-size: 12px;
}

.controls {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.controls > * {
  margin-left: 2px;
}

.icon {
  font-size: 22px;
  color: #fff;
}

.localControls {
  padding: 24px 32px;
}
@import "../../../../../node_modules/@blueprintjs/core/lib/scss/variables";

.container {
  margin-top: 16px;
  display: flex;
  align-items: center;
}

.image {
  flex-shrink: 0;
  flex-grow: 0;
  height: 32px;
  width: 32px;
  border-radius: 16px;
}

.imageEmpty {
  @extend .image;
  background-color: #e2e2e2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.name {
  font-weight: 500;
  margin-left: 8px;
  overflow: hidden;
  flex-grow: 1;
  flex-shrink: 1;
}

.status {
  margin-left: 8px;
}

.statusOnline {
  color: $green4;
}

.statusOffline {
  color: $red4;
}
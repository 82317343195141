.container {
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  .bar {
    padding: 0 50px;
    background: rgba(255, 255, 255, 0.98);
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.06);
    display: flex;
    align-items: center;
    .heading {
      display: flex;
      flex-direction: row;
      align-items: center;
      .title {
        margin-left: 4px;
      }
      .logo {
        width: 24px;
        height: 24px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
  .content {
    // max-width: 600px;
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;
    padding: 0 50px;
    h1 {
      margin-top: 72px;
      text-align: center;
    }
    .links {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      margin-top: 36px;
      .group {
        display: flex;
        flex: 1;
        flex-direction: column;
        margin: 0 36px;
      }
      .link {
        &:hover {
          transform: scale(1.05);
          transition: all .1s ease-in-out;
        }
        background-color: #000;
        width: 216px;
        // height: 50px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        padding: 8px 16px;
        .apple {
          width: 36px;
          height: 36px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
        .text {
          margin-left: 16px;
          color: #fff;
          padding-top: 4px;
          .title {
            font-size: 10px;
          }
          .title2 {
            font-weight: 600;
          }
        }

        margin-bottom: 16px;
      }
    }
  }
}

@import "~@blueprintjs/core/lib/scss/variables";

.layout {
  display: flex;
  height: 100%;
  background-color: #fafafa;
  overflow: hidden;
}

.fullscreenMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.room {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.roomJoined {
  justify-content: unset;
}

.roomParticipants {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 48px 48px 0;
  overflow: auto;
}

.roomParticipantsJoined {
  flex-grow: 1;
  padding: 48px;
}

.roomParticipants > * {
  margin: 8px;
}

.footer {
  padding: 24px 32px;
}

.join {
  margin-top: 24px;
  display: flex;
  justify-content: center;
  flex: 250px 0 0;
}

.joinButton.joinButton {
  box-shadow: none;
  width: 100%;

  &:hover {
    box-shadow: none;
  }
}